<template>
  <div id="app"
       style="background-color: #FFFFFF; min-height: 100vh; padding-top: 3vw; padding-left: 3vw; padding-right: 3vw;">
    <div
        style="border: 1px solid #E5E5E5; border-radius: 4px; width: 94vw; display: flex; padding: 8px 12px 8px 12px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);">
      <span style="flex: 1; color: #333333; font-size: 16px; font-weight: bold;">
        {{ user.name }}
      </span>
      <span style="color: #999999">
        {{ user.business.name }}
      </span>
    </div>

    <img
        style="width: 94vw; height: 40vw; margin-top: 8px; border-radius: 4px;"
        :src="require('../../../src/assets/images/kitchen/banner_index_2.jpg')"
        alt=""/>

    <div
        style="border: 1px solid #E5E5E5; border-radius: 4px; width: 94vw; display: flex; padding: 8px 0px 8px 0px; margin-top: 8px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);"
        v-if="isManager">
      <div style="flex: 1; display: grid; justify-items: center;" @click="routeToCameraList">
        <img
            style="width: 56px; height: 56px; "
            :src="require('../../../src/assets/images/kitchen/icon_camera.png')"
            alt=""/>
        <span style="color: #333333">安装区</span>
      </div>
      <div style="flex: 1; display: grid; justify-items: center;" @click="routeToUserList">
        <img
            style="width: 56px; height: 56px; "
            :src="require('../../../src/assets/images/kitchen/icon_user.png')"
            alt=""/>
        <span style="color: #333333">用户管理</span>
      </div>
      <div style="flex: 1; display: grid; justify-items: center;" @click="routeToAlarmSetting">
        <img
            style="width: 56px; height: 56px; "
            :src="require('../../../src/assets/images/kitchen/icon_alarm.png')"
            alt=""/>
        <span style="color: #333333">报警设置</span>
      </div>

    </div>

    <div style="width: 94vw; display: flex; padding: 12px 0px 12px 0px;">
      <span style="color: #333333; font-size: 16px; font-weight: 400;">
        待处理报警列表
      </span>
      <div
          style="border-radius: 36px; background-color: #ff6666; width: 18px; height: 18px; align-self: center; margin-left: 4px; line-height: 18px; font-size: 10px; color: #FFFFFF; text-align: center;"
          v-if="alarm.totalNum > 0">
        {{ alarm.totalNum }}
      </div>

      <span style="flex: 1;">

      </span>
      <span style="color: #999999" @click="routeToAlarmList">
        全部 >
      </span>
    </div>

    <van-list
        v-model="alarmLoading"
        :finished="alarmFinished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div v-for="(item, index) in alarm.list" :key="index" style="margin-bottom: 12px"
           @click="routeToAlarmDetail(item)">
        <div style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px;">
          <div style="display: flex ;background: #FFEBDA; height: 38px; border-radius: 7px 7px 0px 0px;">
                <span style="color: #FF6666; line-height: 38px; margin-left: 12px;">{{
                    handleAlarmType(item.alarmType)
                  }}</span>
            <span style="flex: 1; text-align: right; color: #FF6666; line-height: 38px; margin-right: 12px;">{{
                item.state === 'PENDING' ? '待处理' : '处理中'
              }}</span>
          </div>
          <div style="display: flex; margin-top: 12px; margin-bottom: 12px">
            <span style="flex: 1; color: #333333; font-size: 14px; margin-left: 12px; line-height: 14px;"><span
                style="color: #999999; margin-right: 4px;">
                    发生时间:
                  </span>{{ item.createdTime | moment('yyyy-MM-DD HH:mm:ss') }}</span>

            <span
                style="color: #333333; margin-right: 12px; align-self: center; font-size: 14px; line-height: 14px;">></span>
          </div>
        </div>
      </div>
    </van-list>
  </div>

</template>

<script>
import {post} from "../../utils/httpKitchen";
import Vue from "vue";
import {KITCHEN_ACCESS_TOKEN, KITCHEN_BUSINESS_ID, KITCHEN_USER, KITCHEN_USER_ROLE} from "../../store/mutation-types";
import {Dialog} from "vant";

export default {
  name: "index",
  data() {
    return {
      user: {
        name: '',
        business: {
          name: ''
        }
      },
      isManager: false,
      alarm: {
        list: [],
        totalNum: 0,
      },
      alarmLoading: false,
      alarmFinished: false,
      load: {
        page: 0,
        size: 20,
      },
    }
  },
  mounted() {
    // 防止后退
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });

    this.user = Vue.ls.get(KITCHEN_USER);
    if (!this.user) {
      this.user = {
        name: '',
        role: '',
        business: {
          name: ''
        }
      }
    }
    // Vue.ls.set(KITCHEN_BUSINESS_ID, '0180270a939f000440289f9c802709ef');
    this.fetchUserInfo();
  },
  methods: {
    onLoad() {
      post('api/kitchen/app/v1/alarm/search', {
        page: this.load.page,
        size: this.load.size,
        filters: [
          {
            name: 'state',
            op: 'in',
            value: [
              'PENDING',
              'PROCESSING'
            ]
          }
        ],
        sort: 'createdTime DESC'
      })
          .then(res => {
            this.load.page = this.load.page + 1;

            this.alarm.list = this.alarm.list.concat(res.data.rows);
            this.alarm.totalNum = res.data.total;

            // 加载状态结束
            this.alarmLoading = false;

            // 数据全部加载完成
            if (this.alarm.list.length >= res.data.total) {
              this.alarmFinished = true;
            }
          })
          .catch(err => {

          });
    },
    fetchUserInfo() {
      post('api/kitchen/app/v1/public/user/findByToken', {})
          .then(res => {
            if (res.data) {
              Vue.ls.set(KITCHEN_USER, res.data);

              this.user = res.data;

              if (res.data.state === 'ENABLED') {
                Vue.ls.set(KITCHEN_BUSINESS_ID, res.data.businessId);
                Vue.ls.set(KITCHEN_USER_ROLE, res.data.role);

                if (this.user.role === 'KITCHEN_MANAGE') {
                  this.isManager = true;
                } else {
                  this.isManager = false;
                }

              } else {
                Dialog.alert({message: '账户已被禁用，请重新登录或联系管理员'}).then(() => {
                  this.backToLogin();
                });
              }
              return;
            }

            this.backToLogin();
          })
          .catch(err => {

          });
    },
    backToLogin() {
      Vue.ls.set(KITCHEN_USER, null);
      Vue.ls.set(KITCHEN_BUSINESS_ID, null);
      Vue.ls.set(KITCHEN_ACCESS_TOKEN, null);
      this.$router.replace({path: '/kitchen/login'});
    },
    handleAlarmType(alarmType) {
      switch (alarmType.toString()) {
        case "6":
          return "违规打电话";
        case "7":
          return "违规吸烟";
        case "31":
          return "未佩戴厨师帽";
        case "32":
          return "鼠患检测";
        case "33":
          return "未佩戴口罩";
        case "34":
          return "未穿厨师服";
      }
    },
    routeToCameraList() {
      this.$router.push({name: "kitchenCameraList"});
    },
    routeToUserList() {
      this.$router.push({name: "kitchenUserList"});
    },
    routeToAlarmSetting() {
      this.$router.push({name: "kitchenAlarmSetting"});
    },
    routeToAlarmList() {
      this.$router.push({name: "kitchenAlarmList"});
    },
    routeToAlarmDetail(value) {
      this.$router.push({name: "kitchenAlarmDetail", query: {id: value.id}});
    }
  }
}
</script>

<style scoped>

</style>